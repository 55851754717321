<template>
  <!-- 首页方案一 -->
  <div class="service">
    <Scroll
      class="region-scroll"
      ref="scroll"
      @scrollEnd="scrollEnd"
      @scroll="scroll"
      :probeType='3'
    >
      <!-- 搜索 -->
      <van-search
        class="search"
        v-model="value"
        shape="round"
        placeholder="请输入搜索关键词"
      />
      <div class="service-box">
        <div class="service-head-box">
          <!-- 轮播图 -->
          <Swiper :list="list" />
        </div>
        <div>
          <!-- 4个大按钮 -->
          <div class="serve in-size">
            <div
              class="serve-s"
              v-for="item in typeList"
              :key="item.id"
              @click="clickJump(item.url,item.name,item.stat)"
            >
              <div>
                <div>
                  <span>{{item.name}}</span>
                  <svg
                    class="icon"
                    aria-hidden="true"
                  >
                    <use
                      class="icon-u"
                      xlink:href="#icon-fanhui-copy"
                    ></use>
                  </svg>
                </div>
                <div style="font-weight:300;font-size:0.8rem">服务</div>
              </div>
            </div>
          </div>
          <!-- 常用功能组件 -->
          <div class="common-functions-title">
            <span></span>
            <h3>智慧服务</h3>
            <span></span>
          </div>
          <!-- 智慧服务 -->
          <div class="list">
            <div
              class="list-each"
              v-for="item in type"
              :key="item.id"
              @click="clickJump(item.url,item.name,item.stat)"
            >
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use
                  class="icon-u"
                  :xlink:href="'#' + item.logo"
                ></use>
              </svg>
              <span class="in-size">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 健康资讯 -->
      <div class="healthmessage">
        <h3>健康资讯</h3>
        <div class="msitem">
          <div class="mscontent lagre">【网上医院】攻略之电话问诊
          </div>
          <div class="msimg">
            <img
              src="//bpic.588ku.com/illus_water_img/21/01/20/83606779d57c8e0af5a819b4dab6a7b3.jpg!/fw/750/quality/99/unsharp/true/compress/true"
              alt=""
            >
          </div>
        </div>
        <div class="msitem">
          <div class="mscontent lagre">【健康惠民】"网上医院"攻略之化验检查沙嗲时候撒给当地胜多负少付水电费第三方的萨达所大所大所大所多啊实打实多是大大大实打实</div>
          <div class="msimg">
            <img
              src="//bpic.588ku.com/illus_water_img/21/01/20/83606779d57c8e0af5a819b4dab6a7b3.jpg!/fw/750/quality/99/unsharp/true/compress/true"
              alt=""
            >
          </div>
        </div>
        <div class="msitem">
          <div class="mscontent lagre">"网上医院"火爆来袭~快来快来体验稍等哈胡搜IDhi啊</div>
          <div class="msimg">
            <img
              src="//bpic.588ku.com/illus_water_img/21/01/20/83606779d57c8e0af5a819b4dab6a7b3.jpg!/fw/750/quality/99/unsharp/true/compress/true"
              alt=""
            >
          </div>
        </div>
      </div>
    </Scroll>
    <!-- 登录按钮 -->
    <div
      :class="loginShow ? 'login-button-left':'login-button-right'"
      @click="switchClick"
    > {{$store.state.nameState == "未登录" ? '绑卡' : $store.state.nameState.trim()}}</div>
  </div>
</template>

<script>
import Swiper from '@/components/content/swiper/Swiper'
import Scroll from '@/components/content/scroll/BScroll'
import { Debounce } from '@/common/utils'

import { getMenuInfo } from '@/network/service'

export default {
  name: 'serviceSchemeOne',
  components: {
    Swiper,
    Scroll
  },
  data () {
    return {
      value: '',
      //智慧服务
      type: [],
      //院内服务功能
      typeList: [],
      list: [{
        img: require("@/static/img/imgs3.webp")
      }, {
        img: require("@/static/img/imgs4.webp")
      }],
      //登录按钮显示与隐藏
      loginShow: true,
    }
  },
  mounted () {
    //首页功能模块
    this.getMenuInfoPost()
    //获取绑卡信息
    this.$store.commit('USER_POST')
  },
  methods: {
    //监听滚动
    scrollEnd: Debounce(function () {
      this.loginShow = true
    }, 300),
    scroll () {
      this.loginShow = false
    },
    //首页功能模块
    async getMenuInfoPost () {
      let res = await getMenuInfo()
      if (res.code == '0') {
        this.$store.state.functionalModule = res.data
        //智慧服务
        let data = res.data.sort((a, b) => a.sort - b.sort)
        data.forEach(item => {
          if (item.appRoleId == 1 && item.pid == 4) {
            this.typeList.push(item)
          } else if (item.appRoleId == 1) {
            this.type.push(item)
          }
        })
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
    //用户绑卡或切换
    switchClick () {
      if (this.$store.state.nameState != '未登录') {
        this.$router.push('/user-data')
      } else {
        this.$router.push('/login')
      }
    },
    //跳转
    clickJump (path, menuName, stat) {
      if (stat == 0) {
        this.$store.dispatch('JUMP', { menuName, _this: this, path })
      } else {
        this.$toast('维护中')
      }
    }
  },
}
</script>
<style lang="less" scoped>
/deep/.van-field__control {
  color: #ffffff;
}
/deep/input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ffffff;
}
.service {
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #ffffff !important;
  .region-scroll {
    height: calc(100vh - 3.85rem);
    overflow: hidden;
    .search {
      background-color: @lightColor;
      .van-search__content {
        background: rgba(141, 191, 202, 0.5);
      }
    }
    .head {
      height: 3rem;
      text-align: center;
      h3 {
        background-color: @lightColor;
        color: #ffffff;
        font-size: 1.3rem;
        line-height: 3rem;
        border-bottom: 1px solid @lightColor;
      }
    }
    .search-color {
      background-image: linear-gradient(120deg, #50d996 0%, #3cc7b6 100%);
    }
    .service-box {
      margin-bottom: 1rem;
      .service-head-box {
        background-color: @lightColor;
        padding: 0.5rem 1rem 1.5rem 1rem;
      }
      .common-functions-title {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-top: 0.3rem solid @lineColor;
        h3 {
          text-align: center;
          padding: 0 2rem;
          white-space: nowrap;
        }
        span {
          flex: 0.5;
          border-top: 1px solid @lineColor;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
        .list-each {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 6rem;
          width: 25%;
          .icon {
            font-size: 3rem;
          }
          span {
            margin-top: 0.5rem;
          }
        }
      }
      .ts-h3 {
        margin: 1rem 0 0.5rem 0;
        padding: 0 1rem;
      }
      .ts-list {
        padding: 0 1rem;
      }
      .serve {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0.5rem 1rem;
        font-weight: 600;
        color: rgb(255, 255, 255);
        .icon {
          font-size: 0.9rem;
          padding-left: 0.2rem;
        }
        .serve-s {
          display: flex;
          align-items: center;
          line-height: 2rem;
          background-color: @darkColor;
          white-space: nowrap;
          width: 49%;
          padding: 0.5rem 0;
          padding-left: 1rem;
          border-radius: 0.5rem;
          box-sizing: border-box;
          margin: 0.5rem 0;
        }
      }
      .jk-hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        margin-top: 1rem;
      }
      .jk-list {
        padding: 0 1rem;
        margin-top: 1rem;
      }
    }
    .healthmessage {
      padding: 0.7rem 0.8rem 0 0.5rem;
      border-top: 0.3rem solid @lineColor;
      h3 {
        line-height: 3rem;
        padding-left: 0.5rem;
      }
      .msitem {
        display: flex;
        height: 5.5rem;
        margin: 0.7rem 0;
        border-bottom: 1px solid @lineColor;
        .msimg {
          width: 30%;
          img {
            height: 5rem;
            width: 100%;
            border-radius: 0.3rem;
          }
        }
        .mscontent {
          width: 70%;
          height: 4.5rem;
          line-height: 1.5rem;
          padding: 0 0.5rem;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .login-button-left,
  .login-button-right {
    position: fixed;
    right: 1rem;
    bottom: 5rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    line-height: 4rem;
    text-align: center;
    white-space: nowrap;
    z-index: 99999;
    background-color: @lightColor;
    color: #ffffff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  }
  .login-button-left {
    transform: translateX(2rem) translateX(-50%);
    transition-duration: 0.3s;
  }

  .login-button-right {
    transform: translateX(10rem) translateX(-50%);
    transition-duration: 0.3s;
  }
}
</style>